import React from "react"
import "../styles.scss"
import Meg from "../images/meg-pic.png"

export default function Home() {
  return (
    <>
      <header className="header">
      </header>
      <div className="container">
        {/* <img className="megpic" src={"../meg-pic.png"} alt="picture of Meg" /> */}
        <div className="intro">
          <img className="megpic" src={Meg} alt="picture of Meg" />
          <div className="text-intro">
            <h1>Hello! I'm Meg.</h1>
            <h2></h2>
          </div>
        </div>
      </div>
    </>
  )
}
